.draft {
  // form deprecated styles
  .box,
  .box-row,
  .inner-box,
  .form-group {
    display: inline-block !important;
    float: left; }
  .form-group {
    position: relative;
    padding: 3px;
    .color-preview {
      width: 20px;
      height: 20px; }
    svg {
      height: 20px; } }
  // modal styles
  .modal-dialog {
    max-width: 900px;
    height: 80%;
    top: 70px !important;
    .modal-content {
      min-height: 100%;
      #toolbarModal {
        width: 100%; } } }
  .ql-picker-options {
    .ql-picker-item {
      &:before {
        font-size: inherit !important; } } } }
