// nav should be above the slides
.container-fluid {
  &>.carousel-component {
    .slider-nav {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      &.left {
        left: 0; }
      &.right {
        right: 0; } } } }

@media all and (min-width: 767px) and (max-width: 992px) {
  // disable desktop behaviour if tablet is contained
  .container-fluid {
    &.container-md {
      &>.carousel-component {
        .slider-nav {
          &.left {
            position: relative;
            top: intial;
            transform: initial; }
          &.right {
            position: relative;
            top: intial;
            transform: initial; } } } } }
  .container-fluid-md {
    &>.carousel-component {
      .slider-nav {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        &.left {
          left: 0; }
        &.right {
          right: 0; } } } } }

@media all and (max-width: 766px) {
  // disable desktop behaviour if mobile is contained
  .container-fluid {
    &.container-sm {
      &>.carousel-component {
        .slider-nav {
          &.left {
            position: relative;
            top: intial;
            transform: initial; }
          &.right {
            position: relative;
            top: intial;
            transform: initial; } } } } }
  .container-fluid-sm {
    &>.carousel-component {
      .slider-nav {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        &.left {
          left: 0; }
        &.right {
          right: 0; } } } }
  .card-modal {
    .slider-body {
      .slider-nav {
        width: 20px;
        font-size: 30px;
        position: fixed;
        bottom: 10px;
        &:first-child {
          left: 10px; }
        &:last-child {
          right: 10px; } } } } }
