.horizontal-tabs{
  .nav-item{
    display: flex;
    align-items: center;
    cursor: pointer;
    .nav-link.active{
      color: inherit;
      background: transparent;
    }
  }
  .nav-link{
    display: flex;
    >*{
      width: initial;
    }
  }
}

// deprecated ???
.tabs-menu{
  .tabs{
    .nav-pills{
      border-radius: .25em;
      //box-shadow: 2px 2px 1px #dbdbdb;
      margin-bottom: 15px;
      text-transform: uppercase;
      font-weight: 600;
      @media screen and (max-width: 576px) {
        white-space: nowrap;
        overflow-x: auto;
        .outer, .inner{
          display: none;
        }
      }
      li:first-child a{
        border-radius: 0.75em 0 0 0.75em !important;
        border: 0.5px solid #e3e3e3;
      }
      li:not(:first-child) a{
        border: 1px solid #e3e3e3;
      }
      li:last-child a{
        border-radius:  0 0.75em 0.75em 0 !important;
        border: 0.5px solid #e3e3e3;
      }
      .nav-item{
        margin-right: -1px;
        &.arrow{
            text-transform: capitalize !important;
        }
        .nav-link{
          letter-spacing: 0.05em;
          padding: 10px;
          line-height: 1.25;
          border-radius: 0;
          cursor: pointer;
          height: 100%;
        }
      }
    }
    .tab-content{
      text-align: left;
    }

    .stickyTop{
      position: fixed !important;
      top: 65px !important;
      left: 0 !important;
      z-index: 10000 !important;
      width: 100% !important;
    }
  }
}
