.page-container {
  min-height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  &.page-container-sidebar {
    .page-content {
      padding: 0; } }
  .page-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 30px 0; }
  .divider-right {
    border-right: 1px solid #E8ECF1; }
  .divider-bottom {
    border-bottom: 1px solid #E8ECF1; } }
