@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

a{
  &.btn:not([class*="bob-"]){
    *:not(style){
      display: inline-block;
      margin: 0;
      padding: 0;
    }
  }  
}

//Fix for firefox on Linux
input{
  background-color: inherit;
}
p{
  margin: 0;
}
.pai-nosso{
  -webkit-overflow-scrolling: touch;
}

.no-hub{
  background-color: $fluxio-blue;
  color: #ffffff;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  img{
    width: 150px;
  }
  &.h100{
    height: 100%;
  }
  .fas{
    font-size: 60px;
    margin-bottom: 5px;
  }
  p.msg-hub{
    margin: 0;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
  }
}

.modal-backdrop{
  z-index: 1000000;
}

.modal-dialog{
  text-align: left;
}

.select2-container{
  z-index: 10000;
  width: 100% !important;
  margin-bottom: 5px;
}

pre{
  margin: 15px;
}

p.smallaf{
  font-size: 10px;
}

.dangerousHTML{
  width: 100%;
}

.loading{
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  img{
    -webkit-animation:spin 7s linear infinite;
    -moz-animation:spin 7s linear infinite;
    animation:spin 7s linear infinite;
    width: 150px;
  }
}

li.nav-item.arrow{
  max-width: 155px !important;
  min-width: 155px;
}

.inner{
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  //background-color: #D7181F;
  width: 60px;
  height: 60px;
  top: 0px;
  left: -51px;
  position: relative;
  border-radius: 5px;
}

.component-error{
  background-color: #fcf0ed;
  border: 1px solid #d50000;
  color: white;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  h2{
    font-size: 16px;
    color: #d50000;
    font-weight: bold;
    text-transform: uppercase;
  }
  span{
    color: #363636;
    font-size: 14px;
    display: block;
  }
  details{
    border-top: 1px solid #d50000;
    color: #363636;
    font-size: 12px;
    line-break: anywhere;
    margin-top: 10px;
    padding-top: 10px;
  }
}

footer.site-footer{
  .line{
    width: 100% !important;
    border: none;
  }
}

.ql-align-center{
  text-align: center;
}

.wrapper-content{
  display: none;
}

html{
  scroll-behavior: smooth;
}

@media all and(max-width: 992px){
  // Reset titles line height
  h1,h2,h3,h4,h5,h6,
  .title, .post-title, .card.title{
    line-height: 1;
  }
}


video{
  width: 100%;
}

.modal-component-backdrop{
  z-index: 1040
}