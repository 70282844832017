//TODO Move to utils
@media all and (max-width: 768px) {
  .card-modal {
    .slider-body {
      .slider-nav {
        width: 20px;
        font-size: 30px;
        position: fixed;
        bottom: 10px;
        &:first-child {
          left: 10px; }
        &:last-child {
          right: 10px; } } } } }
