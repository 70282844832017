// CONTAINER
// tablet styles
@media all and (max-width: 992px) and (min-width: 767px) {
  .container-md {
    width: 100% !important;
    padding-right: 15px !important;
    padding-left: 15px !important;
    margin-right: auto !important;
    margin-left: auto !important;
    max-width: 720px !important; }
  .container-fluid-md {
    width: 100% !important;
    max-width: 100% !important; } }

// mobile styles
@media all and (max-width: 766px) {
  .container-sm {
    width: 100% !important;
    padding-right: 15px !important;
    padding-left: 15px !important;
    margin-right: auto !important;
    margin-left: auto !important;
    max-width: 540px !important; }
  .container-fluid-sm {
    width: 100% !important;
    max-width: 100% !important; } }

// bootstrapp container width breakpoint values
// @media (min-width: 1200px)
// .container {
//     max-width: 1140px;
// }
// @media (min-width: 992px)
// .container {
//     max-width: 960px;
// }
// @media (min-width: 768px)
// .container {
//     max-width: 720px;
// }
// @media (min-width: 576px)
// .container {
//     max-width: 540px;
// }

// GUTTERS
// tablet styles
@media all and (max-width: 992px) and (min-width: 767px) {
  .no-gutters-md {
    margin-right: 0 !important;
    margin-left: 0 !important;
    &>.col,
    &>[class*=col-] {
      padding-right: 0 !important;
      padding-left: 0 !important; } }
  .gutters-md {
    margin-right: 15px !important;
    margin-left: 15px !important;
    &>.col,
    &>[class*=col-] {
      padding-right: 15px !important;
      padding-left: 15px !important; } } }

// mobile styles
@media all and (max-width: 766px) {
  .no-gutters-sm {
    margin-right: 0 !important;
    margin-left: 0 !important;
    &>.col,
    &>[class*=col-] {
      padding-right: 0 !important;
      padding-left: 0 !important; } }
  .gutters-sm {
    margin-right: 15px !important;
    margin-left: 15px !important;
    &>.col,
    &>[class*=col-] {
      padding-right: 15px !important;
      padding-left: 15px !important; } } }

// bootstrapp no gutters values
// .no-gutters {
//     margin-right: 0;
//     margin-left: 0;
// }
// bootstrapp gutters values
// .row {
//     ...
//     margin-right: -15px;
//     margin-left: -15px;
// }
