// safari fix
// must force card-body flex 100% to make childs grow 100% of it's height
.safari-old-version {
  .cards {
    .card {
      .card-body {
        flex: 1 1 100%; } } } }

// tablet styles
@media all and (max-width: 992px) and (min-width: 767px) {
  .cards {
    .card {
      &>.container-md,
      &>.container-fluid-md {
        flex-direction: column; } } } }

// mobile styles
@media all and (max-width: 766px) {
  .cards {
    .card {
      &>.container-sm,
      &>.container-fluid-sm {
        flex-direction: column; } } } }
