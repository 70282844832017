// fonts
$meshapp-font-family-em: Eina;
$meshapp-font-size-xs: 9px;
$meshapp-font-size-sm: 12px;
$meshapp-font-size-md: 14px;
$meshapp-font-size-lg: 18px;
$meshapp-font-size-xl: 22px;
$meshapp-font-weight-normal: 400;
$meshapp-font-weight-semibold: 600;
$meshapp-font-weight-bold: 700;

// colors
$meshapp-blue: #4fb5ff;
$fluxio-blue: #4253FC;
$fluxio-orange: #FD7900;
$fluxio-orange-50: rgba(253, 121, 0, 0.50);
$fluxio-orange-60: rgba(253, 121, 0, 0.60);
$fluxio-light-blue: #4FB5FF;
$fluxio-light-blue-50: rgba(79, 181, 255, 0.50);
$fluxio-light-blue-60: rgba(79, 181, 255, 0.60);
$fluxio-green: rgba(58, 211, 147, 1);
$fluxio-green-50: rgba(58, 211, 147, 0.50);
$fluxio-green-60: rgba(58, 211, 147, 0.60);
$meshapp-grey: #d3d6dc;
$meshapp-grey-light: #e9ebef;
$meshapp-grey-dark: #767a81;
$meshapp-grey-darker: #767a81;
$meshapp-light: #f4f6f8;
$meshapp-dark: #27272d;

// spacing
$meshapp-spacing-xs: 5px;
$meshapp-spacing-sm: 10px;
$meshapp-spacing-md: 20px;
$meshapp-spacing-lg: 40px;
$meshapp-spacing-xl: 80px;

// borders
$meshapp-border-xs: 1px;
$meshapp-border-md: 2px;
$meshapp-border-lg: 4px;
$meshapp-border-radius-xs: 2px;
$meshapp-border-radius-md: 4px;
$meshapp-border-radius-lg: 6px;
$meshapp-border-radius-semicircle: 25%;
$meshapp-border-radius-circle: 50%;

//Breakpoints
$meshapp-breakpoint-xs: 0px;
$meshapp-breakpoint-sm: 576px;
$meshapp-breakpoint-md: 768px;
$meshapp-breakpoint-lg: 992px;
$meshapp-breakpoint-xl: 1200px;
