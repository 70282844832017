.pai-nosso,
#board-preview {
  //Rule for every child of another container
  //To not apply the safe padding around
  .container {
    .component-boilerplate {
      &>.container {
        padding: 0; } } }
  .container-fluid {
    .component-boilerplate {
      &>.container-fluid {
        padding: 0; } } }
  .container {
    .component-boilerplate {
      &>.container-fluid {
        padding: 0; } } }
  .container-fluid {
    .component-boilerplate {
      &>.container {
        padding: 0; } } }

  .no-hub {
    width: 100%; } }

.component-boilerplate {
  position: relative; }
