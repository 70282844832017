.btn {
  border: 1px solid transparent;
  $main-shadow: 0px 2px 3px rgba(144, 148, 189, 0.1);
  &.btn-fluxio {
    padding: 6px 20px;
    font-weight: 600;
    border-radius: 3px;
    font-size: 13px;
    outline: none;
    font-family: 'Eina', Arial, Helvetica;
    &:active,
    &:focus {
      outline: none;
      box-shadow: none; }
    &.btn-primary {
      color: #fff;
      background-color: $fluxio-blue;
      border-color: $fluxio-blue;
      box-shadow: $main-shadow;
      &:hover {
        background-color: #828CF3;
        border-color: #828CF3; }
      &:focus,
      &:active,
      &:active:focus {
        background-color: $fluxio-blue;
        border-color: $fluxio-blue;
        box-shadow: 0 0 2px 0 #828CF3;
        outline: none; } }
    &.btn-outline {
      background-color: #fff;
      border-color: #D3D4E1;
      box-shadow: $main-shadow;
      &:hover {
        border: 1px solid $fluxio-blue; }
      &:focus,
      &.active {
        border: 1px solid $fluxio-blue;
        color: $fluxio-blue; }
      &:active {
        border: 1px solid $fluxio-blue;
        color: $fluxio-blue;
        box-shadow: 0 0 2px 0 #828CF3; }
      &:active:focus {
        border: 1px solid $fluxio-blue;
        color: $fluxio-blue;
        box-shadow: 0 0 2px 0 #828CF3; }
      &:disabled {
        &:hover {
          border-color: #D3D4E1 !important; } }
      .fas {
        color: #b8bac5; } }
    &.btn-ghost {
      background-color: transparent;
      border-color: transparent;
      &:hover {
        background-color: #EFF1FF;
        color: #000; }
      &:focus {
        border: 1px solid $fluxio-blue;
        color: $fluxio-blue; }
      &:active {
        color: $fluxio-blue; }
      &:active:focus {
        background-color: transparent;
        border: 1px solid transparent; } }
    &.btn-option {
      background-color: transparent;
      border-color: transparent;
      //handle svg color
      svg {
        path {
          fill: #000; } }
      &:hover {
        background-color: #EFF1FF; }
      &:focus,
      &.active {
        border: 1px solid $fluxio-blue; }
      &:active {
        color: #fff;
        background-color: $fluxio-blue;
        svg {
          path {
            fill: #fff; } } }
      &:active:focus {
        color: #fff;
        background-color: $fluxio-blue; }
      &:disabled {
        color: #4c5157;
        svg {
          path {
            fill: #4c5157; } } } }
    &.btn-block + .btn-block {
      margin: 0; }
    &.btn-spaced {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 10px; }
    &.btn-em {
      background-color: $fluxio-blue;
      font-size: 13px;
      font-weight: 500;
      border: none;
      border-radius: 5px;
      padding: 10px 20px;
      appearance: none;
      &[disabled],
      &:disabled,
      &.disabled {
        background: #e9ebef;
        cursor: not-allowed;
        pointer-events: none;
        opacity: 1; } }
    &.btn-sm {
      padding: 5px;
      font-size: .875rem;
      line-height: 1.5;
      border-radius: .2rem;
      i {
        font-size: 9px;
        display: flex; } }
    &.btn-xs {
      padding: 2px;
      font-size: .700rem;
      line-height: 1;
      border-radius: 2px;
      i {
        font-size: 9px;
        display: flex; } }
    &.btn-lg {
      padding: 9px 26px; }
    &.btn-icon {
      background-color: transparent;
      padding: 3px 10px; }
    &.btn-date-handler {
      font-size: 14px;
      width: 100%; }
    &.btn-danger {
      color: #fff;
      background-color: #dc3545;
      border-color: #dc3545; }
    &.btn-info {
      color: #fff;
      background-color: #17a2b8;
      border-color: #17a2b8; }
    &.btn-success {
      color: #fff;
      background-color: #00AA8C;
      border-color: #00AA8C; }
    &.btn-action {
      color: #fff;
      background-color: #ff8100;
      border-color: #ff8100; }
    &.btn-default {
      color: #fff;
      background-color: #6c757d;
      border-color: #6c757d; }
    &.btn-secondary {
      background-color: #868686;
      border-color: #868686;
      border-color: #868686; }
    &.btn-transparent {
      color: #333;
      background-color: transparent;
      border-color: transparent;
      &:hover {
        color: initial; }
      &:disabled {
        color: #555;
        background: initial;
        .fas {
          color: #555; } } }
    &.btn-link {
      color: $fluxio-blue;
      background: transparent; }
    &.btn-shortcuts {
      color: #333333;
      background-color: #fff;
      border-color: transparent;
      border-radius: 5px;
      font-size: 14px;
      font-weight: bold;
      line-height: 17px;
      padding: 10px 20px;
      svg {
        width: 80px;
        height: 30px;
        fill: $fluxio-blue; }
      &:hover {
        background-color: $fluxio-blue;
        color: #fff;
        svg {
          fill: #fff; } } } }
  &:disabled {
    &:hover {
      border-color: initial !important; }
    &:focus {
      border-color: initial !important;
      color: initial !important; }
    &:active {
      border-color: initial !important;
      color: initial !important;
      box-shadow: initial !important; }
    &:active:focus {
      border-color: initial !important;
      color: initial !important;
      box-shadow: initial !important; } }
  &.btn-em {
    &:hover {
      color: #fff; } } }
// btn group
.btn-group {
  .btn {
    &:not(:last-child) {
      border-right: 2px solid #fff;
      z-index: initial; } }
  &>.btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  &>.btn:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; } }
