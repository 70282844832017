a.bob-background {
  display: block; }
// containers must be 100% it's bob parent size for alignment purposes
.bob-background > .container,
.bob-background > .container-fluid,
.bob-background > .container > *,
.bob-background > .container-fluid > * {
  width: 100%;
  height: 100%; }

// need to remover inner spacing from .container-fluid due to child .container-fluid's
.bob-background {
  .no-gutters {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  &>.container-fluid {
    padding-left: 0;
    padding-right: 0; } }
// remove inner spacing only from outside .container-fluid's rows (direct child of .browser-content)
.component-boilerplate {
  .bob-background {
    .container-fluid {
      .row {
        margin-right: 0;
        margin-left: 0; } } }
  .component-boilerplate {
    .bob-background {
      .container {
        .row {
          margin-right: -15px;// bootstrap value
          margin-left: -15px; } } } } }// bootstrap value
// dont remove margins on fluid as container child
.component-boilerplate {
  .bob-background {
    .container {
      .container-fluid {
        .row {
          margin-right: -15px;// bootstrap value
          margin-left: -15px; } } } } }// bootstrap value

// safari 100% height fix
// check: https://stackoverflow.com/questions/33636796/chrome-safari-not-filling-100-height-of-flex-parent
.safari-old-version {
  .bob-background > .container,
  .bob-background > .container-fluid,
  .bob-background > .container > *,
  .bob-background > .container-fluid > * {
    height: auto !important; // must be set to auto for the align-items property to work
    min-height: 100% !important; } } // force items to expand 100% of it's parent height

// tablet styles
@media all and (max-width: 992px) and (min-width: 767px) {
  // containers must be 100% it's bob parent size for alignment purposes
  .bob-background > .container-md,
  .bob-background > .container-fluid-md,
  .bob-background > .container-md > *,
  .bob-background > .container-fluid-md > * {
    width: 100%;
    height: 100%; }
  // need to remover inner spacing from .container-fluid due to child .container-fluid's
  .bob-background {
    &>.container-fluid-md {
      padding-left: 0;
      padding-right: 0; } }
  // remove inner spacing only from outside .container-fluid's rows (direct child of .browser-content)
  .component-boilerplate {
    .bob-background {
      .container-fluid-md {
        .row {
          margin-right: 0;
          margin-left: 0; } } }
    .component-boilerplate {
      .bob-background {
        .container-md, {
          .row {
            margin-right: -15px;// bootstrap value
            margin-left: -15px; } } } } }// bootstrap value
  // dont remove margins on fluid as container child
  .component-boilerplate {
    .bob-background {
      .container-md, {
        .container-fluid-md, {
          .row {
            margin-right: -15px;// bootstrap value
            margin-left: -15px; } } } } }// bootstrap value

  // safari 100% height fix
  // check: https://stackoverflow.com/questions/33636796/chrome-safari-not-filling-100-height-of-flex-parent
  .safari-old-version {
    .bob-background > .container-md,
    .bob-background > .container-fluid-md,
    .bob-background > .container-md > *,
    .bob-background > .container-fluid-md > * {
      height: auto !important; // must be set to auto for the align-items property to work
      min-height: 100% !important; } } } // force items to expand 100% of it's parent height

// mobile styles
@media all and (max-width: 766px) {
  // containers must be 100% it's bob parent size for alignment purposes
  .bob-background > .container-sm,
  .bob-background > .container-fluid-sm,
  .bob-background > .container-sm > *,
  .bob-background > .container-fluid-sm > * {
    width: 100%;
    height: 100%; }
  // need to remover inner spacing from .container-fluid due to child .container-fluid's
  .bob-background {
    &>.container-fluid-sm {
      padding-left: 0;
      padding-right: 0; } }
  // remove inner spacing only from outside .container-fluid's rows (direct child of .browser-content)
  .component-boilerplate {
    .bob-background {
      .container-fluid-sm {
        .row {
          margin-right: 0;
          margin-left: 0; } } }
    .component-boilerplate {
      .bob-background {
        .container-sm {
          .row {
            margin-right: -15px;// bootstrap value
            margin-left: -15px; } } } } }// bootstrap value
  // dont remove margins on fluid as container child
  .component-boilerplate {
    .bob-background {
      .container-sm {
        .container-fluid-sm {
          .row {
            margin-right: -15px;// bootstrap value
            margin-left: -15px; } } } } }// bootstrap value
  // safari 100% height fix
  // check: https://stackoverflow.com/questions/33636796/chrome-safari-not-filling-100-height-of-flex-parent
  .safari-old-version {
    .bob-background > .container-sm,
    .bob-background > .container-fluid-sm,
    .bob-background > .container-sm > *,
    .bob-background > .container-fluid-sm > * {
      height: auto !important; // must be set to auto for the align-items property to work
      min-height: 100% !important; } } } // force items to expand 100% of it's parent height
