.elements {
  &.button {
    .btn {
      font-size: 0; // fix for inline-block hidden child ocupying space
      cursor: pointer;
      min-width: 100%;
      display: inline-block;
      &>.container,
      &>.container-fluid {
        &>.bob-text {
          width: 100%; }
        &>.bob-media {
          width: initial;
          flex-shrink: 1; } } } }

  // tablet styles
  @media all and (max-width: 992px) and (min-width: 767px) {
    .btn {
      &>.container-md,
      &>.container-fluid-md {
        &>.bob-text {
          width: 100%; }
        &>.bob-media {
          width: initial;
          flex-shrink: 1; } } } }

  // mobile styles
  @media all and (max-width: 766px) {
    .btn {
      &>.container-sm,
      &>.container-fluid-sm {
        &>.bob-text {
          width: 100%; }
        &>.bob-media {
          width: initial;
          flex-shrink: 1; } } } } }
