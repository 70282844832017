.tab-content{
  margin-top: 20px;
  h3.title{
    margin: 25px 0 10px 0;
    font-size: 27px;
    font-weight: bold;
  }
  h6.subtitle{
    font-size: 18px;
  }
  .line{
    width: 10%;
    border-top: 2px solid rgba(0,0,0,.1);
    margin: 0.5em 1.25em;
  }
}

.pills-menu{
  a{
    color: inherit;
  }
  .nav-link{
    &:hover{
      color: inherit;
    }
    color: black;

  }
  .col-md-4{
    text-align: center;
    .img-under-pills{
      @media only screen and (max-width: 768px){
      }
    }
  }
}

.dropdownPills{
  background-color: #e9e9e9;
  padding: 10px;
  max-width: 100%;
  margin: 0 auto;
  border: solid 1px #d3d3d3;
  border-radius: 2px;
  cursor: pointer;
  text-align: left;

  &:after{
    content: '\f0d7';
    font-family: FontAwesome;
    font-weight: normal;
    font-style: normal;
    margin:0px 0px 0px 10px;
    text-decoration:none;
    float: right;
  }

  &:hover{
    border: solid 1px #c1c1c1;
  }
}

.nav.flex-column{
  text-align: left;
  .nav-item{
    margin-bottom: 13px;
    .nav-link{
      padding: 1px 0 1px 10px;
      &.active{
        font-weight: 600;
      }
    }
  }
}

@media only screen and (min-width: 769px) {
    .dropdownPills {
        display: none;
    }
    .nav.flex-column{
      display: flex;
      margin: 20px 0;
    }
}
@media only screen and (max-width: 768px) {
    .dropdownPills {
        display: block;
    }
    .nav.flex-column{
      display: none;
      background-color: #f3f3f3;
      border: solid 1px #e9e9e9;
      max-width: 100%;
      margin: 0 auto;
      padding: 10px 0 0 10px;
    }
}
