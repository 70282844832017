.bob-media {
  width: 100%;
  height: 100%;
  .bob-image {
    img {
      max-width: 100%; } } }
//When using the player with custom play/pause trigger
.custom-video-player {
  position: relative;
  .play-pause-wrap {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    .play-pause-asset {
      opacity: 1;
      &.playing {
        opacity: 0; } } } }
