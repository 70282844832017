.alert {
  font-size: $meshapp-font-size-sm;
  .msg {
    margin-bottom: 0; }
  &.alert-secondary {
    color: $meshapp-grey-dark; }
  &.alert-dash {
    border-style: dashed; }
  &.alert-transparent {
    background: transparent; }
  &.alert-light {
    padding: 0;
    background: #f7f8f8;
    border-color: #f7f8f8; }
  .alert-title {
    font-size: 30px;
    line-height: 1.13;
    letter-spacing: -0.2px;
    color: #767a81;
    margin-bottom: 20px; }
  .alert-description {
    font-size: 14px;
    line-height: 1.14;
    letter-spacing: -0.1px;
    color: #a3a7af;
    margin-bottom: 50px; }
  .alert-graphic {
    color: #d3d6dc;
    font-size: 80px; }

  .alert-group {
    display: flex;
    align-items: center;
    .alert-graphic {
      margin-right: 20px; }
    .alert-message {
      .alert-description {
        margin-bottom: 0; } }
    .btn {
      margin-top: 10px; }
    &.small {
      .alert-title {
        font-size: 22px;
        margin-bottom: 10px; }
      .alert-graphic {
        font-size: 70px; } } } }
