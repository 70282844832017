.image-filter {
  position: relative;
  // display: inline-block
  &.image-filter-container {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0; }
  .image-filter-placeholder {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%; }
  .image-filter-overlay {
    pointer-events: none; // allows clicks throught the overlay
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0; } }
