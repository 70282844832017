// fix to mimic the external cascais header(from their website) spacing behaviour
.dialog-off-canvas-main-canvas { // class name of the external cascais header
  &+#root {
    .cascais-header {
      .container {
        max-width: 1024px;
        padding: 0 10px; } } } }

@media screen and (min-width: 992px) {
  .cascais-site-menu {
    height: 112px !important; } }

@media screen and (max-width: 991px) {
  .cascais-site-menu {
    height: 68px !important; } }
